import React, { useEffect, useState } from "react";
import Pay1 from "../assets/images/pay16.png";
import Pay2 from "../assets/images/pay22.png";
import Pay3 from "../assets/images/pay33.png";
import Pay4 from "../assets/images/pay34.png";
import Pay5 from "../assets/images/pay45.png";
import Pay6 from "../assets/images/pay59.png";
import Pay7 from "../assets/images/pay60.png";
import Pay8 from "../assets/images/pay61.png";
import Pay9 from "../assets/images/pay47.png";
import Gamcare from "../assets/images/gamcare.png";
import AgeLimite from "../assets/images/age-limit.png";
import Regulations from "../assets/images/regulations.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Twitter from "../assets/images/twitter.png";
import pinterest from "../assets/images/pinterest.png";
import youtube from "../assets/images/youtube.png";
import Telegram from "../assets/images/telegram-channel.png";
import GamingLicence from "../assets/images/gaming_license.png";
import AndroidEn from "../assets/images/android-en.png";
import BD from "../assets/images/BD.png";
import Logo from "../assets/images/nav-logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ADMIN_API } from "../common/common";
import { postApiAdmin } from "../service";
import { getImageUrl, Logout } from "../common/Funcation";
import { faEye, faEyeSlash, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { getTheme } from "../common/StyleSeter";

const cookies = new Cookies();
const FooterComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((e: any) => e.isAuthenticated);
  const HeaderData = useSelector((e: any) => e.Header);
  const [menuTab, setMenuTab] = useState<string>("home");
  const DD = useSelector((e: any) => e.domainDetails);
  const [domainDetails, setDomainDetails] = useState(DD);
  const [showPass, setShowPass] = useState(false);
  const [isLoadding, setIsLoadding] = useState(false);

  useEffect(() => {
    setDomainDetails(DD);
  }, [DD]);
  const userId = HeaderData?._id;
  const goToLogin = () => {
    navigate("/login");
  };
  const openMenu = (name: string) => {
    console.log("name :: ", name);
    console.log("menuTab :: befor: ", menuTab);

    // if (name === menuTab) return false;
    if (name === "myaccount") {
      console.log("hhhhh");

      setMenuTab("myaccount");
      // navigate("/account-panal");
    } else if (name === "home") {
      console.log("dfgdfhdg");
      setMenuTab("home");
      navigate("/");
    } else if (name === "deposit") {
      setMenuTab("deposit");
      navigate("/deposit");
    } else if (name === "promotion") {
      console.log("set Menu promp");

      setMenuTab("promotion");
      navigate("/promotion");
    }
  };
  const [pageData, setPageData] = useState<any>({});
  useEffect(() => {
    if (menuTab === "myaccount") {
      // nothing
    } else if (window.location.pathname === "/deposit") {
      setMenuTab("deposit");
    } else if (window.location.pathname === "/promotion") {
      setMenuTab("promotion");
    }
  });
  useEffect(() => {
    console.log("Hell", menuTab);

    //'/admin/downLineList'
    if (menuTab === "myaccount") {
      console.log("isAuthenticated ::", isAuthenticated);

      if (isAuthenticated?.isLogin) {
        getPageData(isAuthenticated.token);
      }
    }
    return () => {};
  }, [menuTab, isAuthenticated]);
  const getPageData = async (token: any = undefined) => {
    let data: any = {
      api: userId && ADMIN_API.PLAYER_GET_PROFILE,
      value: { id: userId },
      token: token ? token : undefined,
    };
    setIsLoadding(true);
    await postApiAdmin(data)
      .then(function (response) {
        console.log(response);
        setPageData(response.data.data);
        dispatch({ type: "USER_DATA", payload: response.data.data });
        setIsLoadding(false);
      })
      .catch((err) => {
        if (err?.response?.data?.statusCode === 401) {
          setIsLoadding(false)
          dispatch({
            type: "AUTHENTICATION",
            payload: { isLogin: false, token: "", changePassword: false },
          });
          Logout();
          navigate("/login");
        }
      });
  };
  const HandleLogOut = (e: any) => {
    e.preventDefault();
    Logout(e);
    dispatch({
      type: "AUTHENTICATION",
      payload: { isLogin: false, token: "" },
    });
    navigate("/login");
  };
  return (
    <>
      <div className="my-footer">
        <div className="footer-label">Payment Methods</div>
        <div className="payment-method-icon">
          <img src={Pay1} alt="Pay" />
          <img src={Pay2} alt="Pay" />
          <img src={Pay3} alt="Pay" />
          <img src={Pay4} alt="Pay" />
          <img src={Pay5} alt="Pay" />
          <img src={Pay6} alt="Pay" />
          <img src={Pay7} alt="Pay" />
          <img src={Pay8} alt="Pay" />
          <img src={Pay9} alt="Pay" />
        </div>

        <div className="dual-grid">
          <div>
            <div className="footer-label">Responsible Gaming</div>
            <div className="safety-icon">
              <img src={Gamcare} alt="Img" />
              <img src={AgeLimite} alt="Img" />
              <img src={Regulations} alt="Img" />
            </div>
          </div>
          <div>
            <div className="footer-label">Community Websites</div>
            <div className="safety-icon">
              <a
                href={`https://www.facebook.com/public/${domainDetails?.facebook}`}
              >
                <img src={Facebook} alt="Social" />
              </a>
              <a href={`https://www.instagram.com/${domainDetails?.instagram}`}>
                <img src={Instagram} alt="Social" />
              </a>
              <a href={`${domainDetails?.Twitter}`}>
                <img src={Twitter} alt="Social" />
              </a>
              <a href={`${domainDetails?.pinterest}`}>
                <img src={pinterest} alt="Social" />
              </a>
              <a href={`${domainDetails?.youtube}`}>
                <img src={youtube} alt="Social" />
              </a>
              <a href={`http://www.t.me/${domainDetails?.telegram}`}>
                <img src={Telegram} alt="Social" />
              </a>
            </div>
          </div>
        </div>
        <div className="dual-grid app-lic">
          <div>
            <div className="footer-label">Gaming License</div>
            <img src={GamingLicence} height="30px" />
          </div>
          <div>
            <div className="footer-label">App Download</div>
            <img src={AndroidEn} height="30px" />
          </div>
        </div>
        <div className="footer-desc">
          <strong>
            Top Betting Exchange Sites Bangladesh, India &amp; South East Asia
          </strong>
          <br />
          A betting exchange is practically an online tool that is designed for
          gamblers to bet directly against each other and not involve any of the
          traditional bookmakers. Cricket Betting indicates two ways of betting
          in a cricket match. The first one is to bet on the overall outcome of
          a live cricket match. The other one is based on the live cricket score
          of a team in the first 6 overs.
          <br />
          Online betting has developed as a booming industry in South East Asia
          especially in Bangladesh and India, where the bettors get to choose
          from an exciting range of Top Betting Exchange Sites in Bangladesh,
          India and others South East Asia Region.
          <br />
          If you find this interesting and are in search of a reliable and safe
          Cricket Betting Sites Bangladesh and India, then you should enrol with
          us. Baji is a reputed online gambling site in the entire Asia. We
          focus on not only cricket predictions but also other exciting online
          gaming products.
        </div>
        <div className="copyright">
          <table>
            <tbody>
              <tr>
                <td>
                  <img src={getImageUrl(domainDetails?.logo)} height={25} alt="copyRight"/>
                </td>
                <td>
                  <div>
                    <strong>Best Quality Platform</strong>
                  </div>
                  <div>@ 2023 {window.location.host.split('.')[0]} copyrights. All Rights Reserve</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {!cookies.get("skyTokenFront") && (
        <div className="footer-btn" id="footer-unloged-button">
          <div className="button1">
            <img src={BD} />
            BDT
            <br />
            English
          </div>
          <div
            className="button2"
            id="signupbtn"
            onClick={() => navigate("/signup")}
          >
            Sign up
          </div>
          <div className="button3" id="loginbtn" onClick={goToLogin}>
            Login
          </div>
        </div>
      )}
      {cookies.get("skyTokenFront") && (
        <div className="logged-footer-btn">
          <div
            className={`logged-btn ${menuTab === "home" ? "b-active" : ""}`}
            id="home"
            onClick={() => openMenu("home")}
          >
            {/* <img src="../../images/extra-icon/home.png" /> */}
            {/* <img src="../../images/extra-icon/icon-home.svg" /> */}
            <img src="../../images/extra-icon/toolbar-icon-home.svg" />
            Home
          </div>
          <div
            className={`logged-btn ${
              menuTab === "promotion" ? "b-active" : ""
            }`}
            id="promotion"
            onClick={() => openMenu("promotion")}
          >
            {/* <img src="../../images/extra-icon/promotion.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-promotion.svg" />
            Promotions
          </div>
          <div
            className={`logged-btn ${menuTab === "deposit" ? "b-active" : ""}`}
            id="deposit"
            onClick={() => openMenu("deposit")}
          >
            {/* <img src="../../images/extra-icon/wallet.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-promotion.svg" />
            Deposit
          </div>
          <div
            className={`logged-btn ${
              menuTab === "myaccount" ? "b-active" : ""
            }`}
            id="myaccount"
            onClick={() => openMenu("myaccount")}
          >
            {/* <img src="../../images/extra-icon/user.png" /> */}
            <img src="../../images/extra-icon/toolbar-icon-deposit.svg" />
            My Account
          </div>
        </div>
      )}

      <div
        className={`profile-page ${menuTab === "myaccount" ? "active" : ""}`}
        id="profile-panel"
      >
        {/* <div className="profile-page active" id="profile-panel"> */}
        <div id="close-profile" onClick={() => openMenu("home")}>
          ×
        </div>

        <div className="circle-design"></div>

        <div className="scrolling-y">
          <br />
          <div className="league-box">
            <div className="league">
              <img src="../../images/extra-icon/1704320386093.png" />
            </div>
            <div className="person">
              <div className="person-name">{pageData?.user_name}</div>
              <div className="person-vp" id="user-vip">
                VIP Points (VP) <span></span> | My VIP <span>»</span>
              </div>
            </div>
          </div>

          <div className="box profile-balance">
            <div className="main-wallet-icon">
            <div style={{ fontSize: "16px" }}>Main wallet</div>
            <span id="login-password-eye" onClick={() => { getPageData(isAuthenticated.token); }}>
            <img src="../../images/extra-icon/myAccount/icon-reload-gold.svg" />
              {/* <FontAwesomeIcon icon={faRefresh} /> */}
              </span>
            {showPass ?
                <span id="login-password-eye" onClick={() => { setShowPass(false) }}><FontAwesomeIcon icon={faEye} /></span>
                :
                <span id="login-password-bool" onClick={() => { setShowPass(true) }}><FontAwesomeIcon icon={faEyeSlash} /></span>
              }
            </div>
            <div
              style={{ color: "orange", fontWeight: "700", fontSize: "16px" }}
            >
              {showPass ? 
              <>
              {isLoadding ? <>
                <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100px"
                            height="40px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g transform="translate(20 50)">
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill={
                                  getTheme(domainDetails?.colorSchema)
                                    ?.headerTextColor
                                }
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                ></animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(40 50)">
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill={
                                  getTheme(domainDetails?.colorSchema)
                                    ?.headerTextColor
                                }
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                ></animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(60 50)">
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill={
                                  getTheme(domainDetails?.colorSchema)
                                    ?.headerTextColor
                                }
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                ></animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(80 50)">
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill={
                                  getTheme(domainDetails?.colorSchema)
                                    ?.headerTextColor
                                }
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
               </> : <> 
                {domainDetails?.currency ? `${domainDetails?.currency} ` : "BDT "}{" "}
                {pageData?.balance}
              </> }
              </> : 
              <>
              ****
              
              </>}
              
            </div>
          </div>

          <div className="box">
            <div className="box-title">
              <span>Funds</span>
            </div>
            <div className="box-content funds">
              <div className="f" onClick={() => navigate("/deposit")}>
                <img src="../../images/extra-icon/myAccount/1_icon-deposit.png" />
                <br />
                Deposit
              </div>
              <div className="f" onClick={() => navigate("/deposit")}>
                {/* <img src="https://ballbet.live/source/icon/logged-footer/Withdrawal.png" /> */}
                <img src="../../images/extra-icon/myAccount/2_icon-withdrawal.png" />
                <br />
                Withdrwal
              </div>
            </div>
          </div>

          <div className="box">
            <div className="box-title">
              <span>History</span>
            </div>
            <div className="box-content history">
              <div
                className="h"
                id="betting-records-btn"
                onClick={() => navigate("/betting-records")}
              >
                {/* <img src="../../images/extra-icon/receipt.png" /> */}
                <img src="../../images/extra-icon/myAccount/3_icon-bet-records.png" />
                Betting Records
              </div>
              <div
                className="h"
                id="turnover-btn"
                onClick={() => navigate("/turnover-records")}
              >
                {/* <img src="../../images/extra-icon/turnover.png" /> */}
                <img src="../../images/extra-icon/myAccount/4_icon-turnover.png" />
                Turnover
              </div>
              <div
                className="h"
                id="txn-records-btn"
                onClick={() => navigate("/transaction-records")}
              >
                {/* <img src="../../images/extra-icon/receipt2.png" /> */}
                <img src="../../images/extra-icon/myAccount/5_icon-Transactions-records.png" />
                Transaction records
              </div>
            </div>
          </div>

          <div className="box">
            <div className="box-title">
              <span>Profile</span>
            </div>
            <div className="box-content profile">
              <div
                className="p"
                id="personal-info"
                onClick={() => navigate("/personal-info")}
              >
                {/* <img src="../../images/extra-icon/user.png" /> */}
                <img src="../../images/extra-icon/myAccount/6_icon-profile.png" />
                Personal Info
              </div>
              <div
                className="p"
                id="reset-password-btn"
                onClick={() => navigate("/reset-password")}
              >
                {/* <img src="../../images/extra-icon/lock.png" /> */}
                <img src="../../images/extra-icon/myAccount/7_icon-resetpasswords.png" />
                Reset Password
              </div>
              <div
                className="p"
                id="announce-btn"
                onClick={() => navigate("/inbox")}
              >
                {/* <img src="../../images/extra-icon/mail.png" /> */}
                <img src="../../images/extra-icon/myAccount/8_icon-inbox.png" />
                Inbox
              </div>
              <div
                className="p"
                id="referral-btn"
                onClick={() => navigate("/referral")}
              >
                <img src="../../images/extra-icon/myAccount/9_icon-referral.png" />
                Referral
              </div>
            </div>
          </div>

          <div className="box">
            <div className="box-title">
              <span>Contact Us</span>
            </div>
            <div className="box-content contact">
              <a
                href={`https://api.whatsapp.com/send?phone=${domainDetails?.whatsapp}`}
                className="c"
              >
                {/* <img src="../../images/extra-icon/whatsapp.png" /> */}
                <img src="../../images/extra-icon/myAccount/10_icon-Whatsapp.png" />
                WhatsApp
              </a>
              <a href={`mailto:${domainDetails?.email}`} className="c">
                {/* <img src="../../images/extra-icon/mail.png" /> */}
                <img src="../../images/extra-icon/myAccount/11_icon-email.png" />
                E-Mail
              </a>
              <a
                href={`https://www.facebook.com/public/${domainDetails?.facebook}`}
                className="c"
              >
                {/* <img src="../../images/extra-icon/facebook.png" /> */}
                <img src="../../images/extra-icon/myAccount/12_icon-facebook-messenger.png" />
                Facebook
              </a>
            </div>
          </div>

          <div className="box" id="logout-btn" onClick={(e) => HandleLogOut(e)}>
            <div className="box-content logout">
              {/* <img id="l" src="../../images/extra-icon/logout.png" /> */}
              <img id="l" src="../../images/extra-icon/myAccount/icon-logout.png" />
              Logout
            </div>
          </div>
        </div>
      </div>
      {/* <MyAccountPanal isActive={menuTab === "myaccount"} /> */}
    </>
  );
};

export default FooterComponent;
