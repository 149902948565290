import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Inbox() {
    const navigate = useNavigate();
    return (
        <>
            <div className="mob-header">
                <div className="back">
                    <img
                        src="../../images/extra-icon/angle-left-white.png"
                        height={22}
                        onClick={() => navigate("/")}
                    />
                </div>
                <div className="center">
                    <h5>Inbox</h5>
                </div>
                <div className="help">
                    <img src="../../images/extra-icon/message.png" alt="Help" height={25} />
                    <span>Help</span>
                </div>
            </div>
            <div className="announce-box">

                <div className="announce">
                    <div className="announce-date-time">
                        <img src={"../../images/extra-icon/date-time.png"}/>
                        <div>10/11/2023</div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title">Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title">Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title" style={{color:'#828282'}}>Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                </div>



                <div className="announce">
                    <div className="announce-date-time">
                        <img src={"../../images/extra-icon/date-time.png"}/>
                        <div>10/11/2023</div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title">Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title">Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                    <div className="announce-part">
                        <div className="announce-left"><img src={"../../images/extra-icon/announce3.png"}/></div>
                        <div className="announce-right">
                            <div className="announce-right-top">
                                <div className="announce-short-title" style={{color:'#828282'}}>Hi Anas! Congratulation!</div>
                                <div className="announce-time">1:33am</div>
                            </div>
                            <div className="announce-short-details">
                                Congratulation for registeration in...
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}
