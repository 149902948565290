import React, { useState } from "react";


const SportsFilter = () => {
    const [activeClass, setactiveClass] = useState<string>('sortByTime')
      return (
    <>
     <div className="sorting-wrap">
	          <ul id="viewType">
	              <li id="sortByTime" className={activeClass === "sortByTime" ? "select" : ""} onClick={()=>setactiveClass("sortByTime")}/*"MobileHighlightHandler.viewType(this)"*/>by Time</li>
	              <li id="sortByCompetition" className={activeClass === "sortByCompetition" ? "select" : ""} onClick={()=>setactiveClass("sortByCompetition")}/*"MobileHighlightHandler.viewType(this)"*/>by Competition</li>
	          </ul>
			</div>
    </>
  );
};

export default SportsFilter;
